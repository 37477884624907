import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'

import SEO from '../components/seo'
import TopNavigation from '../templates/topNav'
import Footer from '../templates/footer'

const Template = ({
  className,
  children,
  dataProps = {},
  layoutProps = {},
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          defaultTitle: title
          siteUrl: url
          titleTemplate
          defaultDescription: description
        }
      }
    }
  `)

  return (
    <div
      className={classNames('floof', layoutProps.className, className)}
      {...dataProps}
    >
      <SEO title={data.title} url={data.siteUrl} />

      <TopNavigation />
      <main className="main" id="content">
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default Template
